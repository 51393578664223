<template
  ><div class="gestion-template-th">
    <div class="entete">
      <div class="box-setting-header ">
        <div class="d-flex">
          <div class="title mr-4">
            Gestion des templates
          </div>
          <div v-if="TemplateLoadingFetch" class="loading">
            <div class="spinner-border loadingFetch" role="status"></div>
          </div>
        </div>

        <div>
          <b-button
            v-if="checkPermission('GTTHAT') && selectedTemplate"
            variant="success"
            class="config-btn ml-2"
            @click.stop="initialisetinymce"
            ><font-awesome-icon icon="plus" /> Ajouter une template
          </b-button>
          <!-- Modal New Template  -->
          <Modal
            v-model="showTemlplate"
            modalClass="ModelClass"
            title="Ajouter une template"
            @after-close="resetModal"
            :no-close-on-backdrop="true"
          >
            <form @submit.prevent="handleSubmitTemplate">
              <b-row class="row mb-1">
                <!-- Title -->
                <b-col col xs="12" sm="4" md="4">
                  <b-form-group
                    label="Titre * "
                    label-for="title-template-input"
                    class=" input-modal-champ "
                  >
                    <b-form-input
                      autocomplete="off"
                      id="title-template-input"
                      v-model="templateToAdd.name"
                      required
                    ></b-form-input>
                  </b-form-group>
                </b-col>

                <!-- Catégorie -->
                <b-col col xs="12" sm="4" md="4">
                  <!-- Catégorie  --><b-form-group
                    label="Catégorie"
                    label-for="categorie-template-select"
                    class=" input-modal-champ "
                  >
                    <b-form-select
                      id="categorie-template-select"
                      v-model="templateToAdd.category_id"
                      :options="computedCategorie"
                      text-field="text"
                      value-field="value"
                      class="b-form-select-raduis "
                      disabled
                    ></b-form-select>
                  </b-form-group>
                </b-col>

                <!-- Groupe -->
                <b-col col xs="12" sm="4" md="4">
                  <b-form-group
                    label="Groupe"
                    label-for="group_id-template-select"
                    class=" input-modal-champ "
                  >
                    <b-form-select
                      id="group_id-template-select"
                      v-model="templateToAdd.group_id"
                      :options="getGroupeTemplate"
                      text-field="name"
                      value-field="id"
                      class="b-form-select-raduis "
                      required
                    ></b-form-select>
                  </b-form-group>
                </b-col>
                <!-- Groupe  -->
              </b-row>
              <b-row>
                <!-- Famille -->
                <b-col col xs="12" sm="12" md="12">
                  <b-form-group
                    label="Famille"
                    label-for="famille-template-select"
                    class="vue-select-modal-champ"
                  >
                    <multiselect
                      v-model="templateToAdd.familles"
                      :options="computedFamille"
                      :multiple="true"
                      :close-on-select="false"
                      :clear-on-select="false"
                      :preserve-search="true"
                      label="text"
                      track-by="value"
                      :preselect-first="false"
                      class="select-vue-component-style mb-4"
                      :showLabels="getTemplateFamilleLoader"
                    >
                      <span slot="noResult">Aucune Famille trouvé.</span>
                      <span slot="noOptions">Aucune Famille trouvé.</span>
                    </multiselect>
                  </b-form-group>
                </b-col>
                <!-- Famille  --></b-row
              >

              <div class=" mb-1 ml-3">
                <!-- Description  -->
                <b-form-group
                  label="Description "
                  label-for="title-template-description"
                  class=" input-modal-champ "
                >
                  <b-form-textarea
                    id="title-template-description"
                    v-model="templateToAdd.description"
                    rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <div class=" mb-1 ml-3">
                <!-- Description 2 -->
                <b-form-group
                  label="Description 2"
                  label-for="title-template-description-2"
                  class=" input-modal-champ "
                >
                  <b-form-textarea
                    id="title-template-description-2"
                    v-model="templateToAdd.description2"
                    rows="3"
                  ></b-form-textarea>
                </b-form-group>
              </div>
              <div class="mb-1 ml-3 titleFields">
                Champs
                <div class="CopieMsg" v-if="copierText">
                  Text copier
                </div>
              </div>
              <div class=" mb-1">
                <!-- Fields -->
                <div
                  v-for="(variable, index) of getTemplatesFields"
                  :key="index"
                >
                  <div class="mb-1 ml-3 titleFields">
                    {{ variable.name }}
                  </div>
                  <b-row class=" mb-1 ml-3">
                    <div
                      v-for="prop of variable.variables"
                      :key="prop.id"
                      @click="CopyFields({ prop: prop.value })"
                      class="filed-class"
                      :title="prop.description"
                    >
                      <div class="icon-field">☰</div>
                      <div class="value-field">
                        {{ prop.key }}
                      </div>
                      <div class="icon-copy">
                        <font-awesome-icon icon="copy" title="Copier" />
                      </div>
                    </div>
                  </b-row>
                </div>
              </div>
              <b-row class="row">
                <!-- Body -->
                <b-col col xs="12" sm="12" md="12">
                  <editor
                    v-model="templateToAdd.content"
                    :init="computedDataTinymce.init"
                  ></editor>
                </b-col>
              </b-row>
              <div class="actionModel text-aligne-center">
                <div class="error-message mb-2 mt-2">
                  <div v-if="getTemplateError" class="error ">
                    <ul v-if="Array.isArray(getTemplateError)" class="mb-0">
                      <li v-for="(e, index) in getTemplateError" :key="index">
                        {{ e }}
                      </li>
                    </ul>
                    <div v-else>{{ getTemplateError }}</div>
                  </div>
                </div>
                <b-button
                  type="submit"
                  class="button-valide-style mb-2 mt-2"
                  :disabled="getNewTemplateLoading"
                >
                  <span>
                    Valider
                    <div v-if="getNewTemplateLoading" class="loading ml-2">
                      <div class="spinner-border" role="status"></div>
                    </div>
                  </span>
                </b-button>
              </div>
            </form>
            <!-- </template>
            </Card> -->
          </Modal>
        </div>
      </div>
      <div class="ligne-box-setting-header mt-1"></div>
    </div>

    <div class="templates-block">
      <b-list-group class="custom-list-group mt-2 mr-4">
        <b-button
          v-if="checkPermission('GTTHAC')"
          variant="success"
          class="config-btn mb-2"
          v-b-modal.addcategorie
          ><font-awesome-icon icon="plus" /> Ajouter une categorie
        </b-button>
        <b-modal
          id="addcategorie"
          ref="addcategorie"
          :hide-footer="true"
          :hide-header="true"
          no-close-on-backdrop
          @hidden="resetModal"
          modal-class="cutsom-modal-bootstrap "
        >
          <div class="hader mb-2">
            <div class="titleSetting">Ajouter un categorie</div>
            <div class="iconClose" @click.prevent="hideModal('addcategorie')">
              <font-awesome-icon icon="times" />
            </div>
          </div>
          <Card>
            <template v-slot:body>
              <form
                @submit.prevent="hendleSubmitcategorie"
                class="form-modal-custom-style"
              >
                <b-form-group
                  label="Nom de categorie"
                  label-for="categrie-name"
                  class="input-modal-champ "
                >
                  <b-form-input
                    autocomplete="off"
                    id="categrie-name"
                    v-model="categorieToAdd"
                    required
                  ></b-form-input>
                </b-form-group>
                <div class="actionModel mt-3">
                  <div class="messageError">
                    <div v-if="error" class="error">
                      <ul v-if="Array.isArray(error)">
                        <li v-for="(e, index) in error" :key="index">
                          {{ e }}
                        </li>
                      </ul>
                      <div v-else>{{ error }}</div>
                    </div>
                  </div>
                  <b-button
                    type="submit"
                    class="button-valide-style "
                    :disabled="getNewTemplateLoading"
                  >
                    <span>
                      Valider
                      <div v-if="getNewTemplateLoading" class="loading ml-2">
                        <div class="spinner-border" role="status"></div>
                      </div>
                    </span>
                  </b-button>
                </div>
              </form>
            </template>
          </Card>
        </b-modal>
        <b-list-group-item
          button
          v-for="categorie in getAllTemplate"
          :key="categorie.name"
          @click.prevent="handleClickTemplate(categorie)"
          class="list-groupe-item-groupe-template"
        >
          <div class="list-item">
            <div
              class="icon"
              v-if="selectedTemplate && selectedTemplate.name == categorie.name"
            >
              <font-awesome-icon icon="check" />
            </div>
            <div>
              {{ categorie.name }}
            </div>
          </div>
          <b-dropdown
            v-if="
              categorie &&
                categorie.id != 0 &&
                (checkPermission('GTTHRC') || checkPermission('GTTHSC'))
            "
            class="drop-down-list-item"
            size="lg"
            variant="link"
            toggle-class="text-decoration-none"
            no-caret
            text="..."
          >
            <b-dropdown-item
              @click.stop="handleUpdatecategorie(categorie)"
              v-if="checkPermission('GTTHRC')"
            >
              <font-awesome-icon class="icon" icon="pencil-alt" />Renommer
            </b-dropdown-item>
            <b-dropdown-item
              @click.stop="handleDeletecategorie(categorie)"
              v-if="checkPermission('GTTHSC')"
            >
              <font-awesome-icon
                class="icon"
                icon="trash-alt"
              />Supprimer</b-dropdown-item
            >
          </b-dropdown>
        </b-list-group-item>
      </b-list-group>
      <div class="mt-2 template-block-style">
        <b-list-group
          v-if="selectedTemplate && selectedTemplate.templates.length > 0"
        >
          <b-list-group-item
            v-for="template in selectedTemplate.templates"
            :key="template.id"
            ><div class="content-template">
              <div class="title">
                {{ template.name }}
              </div>

              <div class="btn-action">
                <b-button
                  v-if="checkPermission('GTTHVT')"
                  size="sm"
                  variant="secondary"
                  title="Visualiser"
                  @click.prevent.stop="handleShowClick(template)"
                >
                  <font-awesome-icon class icon="eye" />
                </b-button>
                <b-button
                  v-if="checkPermission('GTTHMT')"
                  size="sm"
                  variant="primary"
                  title="Modifier"
                  @click.prevent.stop="handleUpdateClick(template)"
                >
                  <font-awesome-icon class icon="pencil-alt" />
                </b-button>
                <b-button
                  v-if="checkPermission('GTTHST') && template.not_deletable == 0"
                  size="sm"
                  title="Supprimer"
                  variant="danger"
                  @click.prevent.stop="handleDeleteClick(template)"
                >
                  <font-awesome-icon class icon="trash-alt" />
                </b-button>
                <!-- <b-button
                  v-if="checkPermission('GTTHTDT')"
                  size="sm"
                  title="Table template"
                  variant="success"
                  @click.prevent.stop="handleTableTemplateClick(template)"
                >
                  <font-awesome-icon class icon="bars" />
                </b-button> -->
              </div>
            </div></b-list-group-item
          >
        </b-list-group>
      </div>
    </div>
    <!-- Colonne Template  -->
    <Modal
      v-model="showTableTemplateModal"
      modalClass="ModelClass"
      title="Table dynamique du template"
      @after-close="resetTableTemplateModal()"
    >
      <form
        v-if="templateToDisplay && templateToDisplay.Columns !== null"
        ref="tableTemplateForm"
        @submit.prevent="submitDynamicTableTemplate"
      >
        <div class="row mb-3">
          <div class="col-2">
            <b-form-group label="Couleur label" class="înput-modal-champ pl-0">
              <v-input-colorpicker
                colorpick-eyedropper-active="false"
                class="color_picker_width "
                v-model="label"
              />
            </b-form-group>
          </div>
          <div class="col-2">
            <b-form-group label="Couleur entête" class="înput-modal-champ pl-0">
              <v-input-colorpicker
                colorpick-eyedropper-active="false"
                class="color_picker_width "
                v-model="color"
              />
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group
              label="Différence de largeur"
              class="înput-modal-champ pl-0"
            >
              <b-form-input
                required
                class="input-modal-champ"
                type="number"
                disabled
                v-model="restSum"
              >
              </b-form-input>
            </b-form-group>
          </div>
          <div class="col-3">
            <b-form-group label="Border" class="înput-modal-champ pl-0">
              <b-form-checkbox
                :id="'checkbox-border'"
                name="checkbox-border"
                class="border-checkbox"
                v-model="border"
              >
              </b-form-checkbox>
            </b-form-group>
          </div>
        </div>
        <div class="row mb-3">
          <div class="col-1"></div>
          <div class="col-1"></div>
          <div class="col-2">
            <b> Code colonne</b>
          </div>
          <div class="col-3">
            <b> Nom de colonne</b>
          </div>
          <div class="col-3">
            <b> Largeur de colonne % </b>
          </div>
        </div>
        <draggable :list="columns">
          <div class="row" v-for="(column, index) in columns" :key="index">
            <div class="col-1">
              <img src="@/assets/sort.png" class="sort-icon ml-2" />
            </div>
            <p class="m-0" hidden>{{ (column.index = index) }}</p>
            <div class="col-1">
              <b-form-checkbox
                :id="'checkbox-1' + index"
                name="checkbox-1"
                v-model="column.check"
                @change="CalculeRestWidth()"
              >
              </b-form-checkbox>
            </div>
            <div class="col-2 ">
              <b-form-group>{{ column.column }}</b-form-group>
            </div>
            <div class="col-3 ">
              <b-form-group>
                <b-form-input
                  required
                  class="input-modal-champ"
                  v-model="column.column_value"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <div class="col-2 ">
              <b-form-group>
                <b-form-input
                  required
                  class="input-modal-champ"
                  v-model="column.width"
                  type="number"
                  @change="CalculeRestWidth()"
                >
                </b-form-input>
              </b-form-group>
            </div>
            <hr />
          </div>
        </draggable>
        <center>
          <div class="actionModel  mt-3">
            <div class="messageError">
              <div v-if="getDynamicTableErrors" class="error">
                <ul v-if="Array.isArray(getDynamicTableErrors)">
                  <li v-for="(e, index) in getDynamicTableErrors" :key="index">
                    {{ e }}
                  </li>
                </ul>
              </div>
              <div v-else class="color-message">{{ sumError }}</div>
            </div>
            <br />
            <b-button
              type="submit"
              class="button-valide-style "
              :disabled="getlodingDynamicTable"
            >
              <span>
                Valider
                <div v-if="getlodingDynamicTable" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div>
        </center>
      </form>
    </Modal>
    <!-- End Colonne Template -->
    <!-- Delete Template Modal -->
    <b-modal
      no-close-on-backdrop
      ref="deleteTemplateModal"
      id="deleteTemplateModal"
      title=" Supprimer une Template "
      :hide-footer="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap "
    >
      <p>
        Êtes-vous certain de vouloir supprimer
        <strong> {{ TemplateToDelete ? TemplateToDelete.name : '' }}</strong>
        ?
      </p>
      <div class="message  text-aligne-center">
        <div class="actionModel">
          <div class="messageError">
            <div v-if="error" class="error">
              <ul v-if="Array.isArray(error)">
                <li v-for="(e, index) in error" :key="'error' + index">
                  {{ e }}
                </li>
              </ul>
              <div v-else>{{ error }}</div>
            </div>
          </div>
          <b-button
            type="submit"
            class="button-valide-style mt-1"
            @click="supprimerTemplate"
            :disabled="getTemplateLoading"
          >
            <span>
              Valider
              <div v-if="getTemplateLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- End Delete Template Modal -->
    <!--show template -->
    <b-modal
      no-close-on-backdrop
      ref="showTemplateModal"
      id="showTemplateModal"
      :hide-footer="true"
      size="md"
      @hidden="hideModal('showTemplateModal')"
      modal-class="cutsom-modal-bootstrap custom-modal-size-pdf"
      title="Template"
    >
      <template>
        <div
          class="loader-export-globale-cdg d-flex justify-content-center"
          v-if="loading == true"
        >
          <Half-circle-spinner
            :animation-duration="1000"
            :size="50"
            :color="'#4d4bac'"
            class="loader"
          />
        </div>
        <div v-else class="template-div-content">
          <iframe width="700" height="700" :src="pdfsource"></iframe>
        </div>
      </template>
    </b-modal>

    <!-- Update Template Modal   -->
    <Modal
      v-model="showTemplateToUpdate"
      modalClass="ModelClass"
      title="Modifier une template"
      @after-close="resetModal"
    >
      <template v-if="getLoadingOneTemplate">
        <div class="message text-aligne-center">
          <div class="loading-custom-template-block style-half-circle-loder">
            <Half-circle-spinner
              :animation-duration="1000"
              :size="50"
              :color="'#4d4bac'"
              class="loeder"
            />
          </div>
        </div>
      </template>
      <!-- <div> -->
      <template v-else>
        <form
          v-if="TemplateToUpdate"
          ref="updateTemplateForm"
          @submit.prevent="handleUpdateTemplate"
        >
          <b-row class="row mb-1">
            <!-- Title -->
            <b-col col xs="12" sm="4" md="4">
              <b-form-group
                label="Titre * "
                label-for="title-template-input"
                class=" input-modal-champ "
              >
                <b-form-input
                  autocomplete="off"
                  id="title-template-input"
                  v-model="TemplateToUpdate.name"
                  required
                ></b-form-input>
              </b-form-group>
            </b-col>
            <!-- Fields -->
            <b-col col xs="12" sm="4" md="4">
              <!-- Catégorie  --><b-form-group
                label="Catégorie"
                label-for="categorie-template-select"
                class=" input-modal-champ "
              >
                <b-form-select
                  id="categorie-template-select"
                  v-model="TemplateToUpdate.category_id"
                  :options="computedCategorie"
                  text-field="text"
                  value-field="value"
                  class="b-form-select-raduis "
                  disabled
                ></b-form-select>
              </b-form-group>
            </b-col>
            <!-- Groupe -->
            <b-col col xs="12" md="4">
              <!-- Groupe  --><b-form-group
                label="Groupe"
                label-for="group_id-template-select"
                class=" input-modal-champ "
              >
                <b-form-select
                  id="group_id-template-select"
                  v-model="TemplateToUpdate.group_id"
                  :options="getGroupeTemplate"
                  text-field="name"
                  value-field="id"
                  class="b-form-select-raduis "
                  required
                ></b-form-select>
              </b-form-group>
            </b-col>
          </b-row>
          <b-row> </b-row>
          <b-row>
            <!-- Famille -->

            <b-col col xs="12" sm="12" md="12">
              <b-form-group
                label="Famille"
                label-for="famille-template-select"
                class="vue-select-modal-champ"
              >
                <multiselect
                  v-model="TemplateToUpdate.familles"
                  :options="computedFamille"
                  :multiple="true"
                  :close-on-select="false"
                  :clear-on-select="false"
                  :preserve-search="true"
                  label="text"
                  track-by="value"
                  :preselect-first="false"
                  class="select-vue-component-style mb-4"
                  :showLabels="getTemplateFamilleLoader"
                >
                  <span slot="noResult">Aucune Famille trouvé.</span>
                  <span slot="noOptions">Aucune Famille trouvé.</span>
                </multiselect>
              </b-form-group>
            </b-col>
            <!-- Famille  --></b-row
          >
          <div class=" mb-1 ml-3">
            <!-- Description  -->
            <b-form-group
              label="Description "
              label-for="title-template-description"
              class=" input-modal-champ "
            >
              <b-form-textarea
                id="title-template-description"
                v-model="TemplateToUpdate.description"
                rows="3"
              ></b-form-textarea>
            </b-form-group>
          </div>

          <div class=" mb-1 ml-3">
            <!-- Description 2   -->
            <b-form-group
              label="Description 2"
              label-for="title-template-description-2"
              class=" input-modal-champ "
            >
              <b-form-textarea
                id="title-template-description-2"
                v-model="TemplateToUpdate.description2"
                rows="3"
              ></b-form-textarea>
            </b-form-group>
          </div>
          <div class="mb-1 ml-3 titleFields">
            Champs
            <div class="CopieMsg" v-if="copierText">
              Text copier
            </div>
          </div>
          <div class=" mb-1">
            <!-- Fields -->
            <div v-for="(variable, index) of getTemplatesFields" :key="index">
              <div class="mb-1 ml-3 titleFields">
                {{ variable.name }}
              </div>
              <b-row class=" mb-1 ml-3">
                <div
                  v-for="prop of variable.variables"
                  :key="prop.id"
                  @click="CopyFields({ prop: prop.value })"
                  class="filed-class"
                  :title="prop.description"
                >
                  <div class="icon-field">☰</div>
                  <div class="value-field">
                    {{ prop.key }}
                  </div>
                  <div class="icon-copy">
                    <font-awesome-icon icon="copy" title="Copier" />
                  </div>
                </div>
              </b-row>
            </div>
          </div>
          <b-row class="row">
            <!-- Body -->
            <b-col col xs="12" sm="12" md="12">
              <editor
                v-model="TemplateToUpdate.content"
                :init="computedDataTinymce.init"
              ></editor>
            </b-col>
          </b-row>
          <div class="actionModel text-aligne-center">
            <div class="error-message mb-2 mt-2">
              <div v-if="getTemplateError" class="error ">
                <ul v-if="Array.isArray(getTemplateError)" class="mb-0">
                  <li v-for="(e, index) in getTemplateError" :key="index">
                    {{ e }}
                  </li>
                </ul>
                <div v-else>{{ getTemplateError }}</div>
              </div>
            </div>
            <b-button
              type="submit"
              class="button-valide-style mb-2 mt-2"
              :disabled="getTemplateLoading"
            >
              <span>
                Valider
                <div v-if="getTemplateLoading" class="loading ml-2">
                  <div class="spinner-border" role="status"></div>
                </div>
              </span>
            </b-button>
          </div>
        </form>
      </template>
    </Modal>
    <!--  Delete categorie Modal -->
    <b-modal
      ref="categorieDeleteModal"
      id="categorieDeleteModal"
      no-close-on-backdrop
      :hide-footer="true"
      title="Supprimer une categorie "
      @hidden="hideModal('categorieDeleteModal')"
    >
      <p>
        Êtes-vous certain de vouloir supprimer la categorie
        <strong class="name">
          {{ selectedcategorieToDelete ? selectedcategorieToDelete.name : '' }}
        </strong>
        ?
      </p>
      <div class="form-modal-custom-style">
        <div class="messageError">
          <div v-if="getTemplateError" class="error">
            <ul v-if="Array.isArray(getTemplateError)">
              <li v-for="(e, index) in getTemplateError" :key="index">
                {{ e }}
              </li>
            </ul>
            <span v-else>{{ getTemplateError }}</span>
          </div>
        </div>
        <div class="actionModel">
          <b-button
            class="button-valide-style "
            @click.prevent="handleModalSupprimerValider"
            :disabled="getTemplateLoading"
          >
            <span>
              Confirmer
              <div v-if="getTemplateLoading" class="loading ml-2">
                <div class="spinner-border" role="status"></div>
              </div>
            </span>
          </b-button>
        </div>
      </div>
    </b-modal>
    <!-- END Delete categorie Modal -->
    <!-- Update Name categorie Modal -->
    <b-modal
      id="UpdateNamecategorieModal"
      ref="UpdateNamecategorieModal"
      no-close-on-backdrop
      :hide-footer="true"
      :hide-header="true"
      @hidden="resetModal"
      modal-class="cutsom-modal-bootstrap"
    >
      <div class="hader mb-3">
        <div class="titleSetting">Renommer une catégorie</div>

        <div
          class="iconClose"
          @click.prevent="hideModal('UpdateNamecategorieModal')"
        >
          <font-awesome-icon icon="times" />
        </div>
      </div>
      <Card>
        <template v-slot:body>
          <form
            @submit.prevent="submitUpdateNamecategorie"
            class="form-modal-custom-style"
          >
            <b-form-group
              label="Nom"
              label-for="Nom"
              class="input-modal-champ"
              v-if="selectedcategorieToUpdate && selectedcategorieToUpdate.name"
            >
              <b-form-input
                autocomplete="off"
                id="Nom"
                v-model="selectedcategorieToUpdate.name"
                required
              ></b-form-input>
            </b-form-group>

            <div class="messageError mt-3">
              <div v-if="getTemplateError" class="error">
                {{ getTemplateError }}
              </div>
            </div>
            <div class="actionModel">
              <b-button
                type="submit"
                class="button-valide-style "
                :disabled="getTemplateLoading"
              >
                <span
                  >Enregistrer
                  <div v-if="getTemplateLoading" class="loading ml-2">
                    <div class="spinner-border" role="status"></div></div
                ></span>
              </b-button>
            </div>
          </form>
        </template>
      </Card>
    </b-modal>
    <!-- END Update Name Modal-->
  </div>
</template>
<script src="//cdn.tinymce.com/4/tinymce.min.js"></script>
<script>
import { mapActions, mapGetters } from 'vuex';
import Editor from '@tinymce/tinymce-vue';
import draggable from 'vuedraggable';
import VueModal from '@kouts/vue-modal';
import InputColorPicker from 'vue-native-color-picker';
import '@kouts/vue-modal/dist/vue-modal.css';
export default {
  name: 'Gestion-des-templates',
  data() {
    return {
      templateToAdd: {
        name: null,
        category_id: null,
        content: null,
        description: null,
        description2: null,
        familles: [],
        group_id: null
      },
      label: null,
      border: false,
      pdfsource: null,
      restSum: 0,
      sumError: null,
      color: null,
      idTemplateToSend: null,
      templateToDisplay: null,
      copierText: false,
      error: null,
      loading: false,
      columns: [],
      showTemlplate: false,
      error: null,
      loading: false,
      TemplateToDelete: null,
      TemplateToUpdate: null,
      TemplateToUpdateOrigine: null,
      TemplateToShow: null,
      showTemplateToUpdate: false,
      showTableTemplateModal: false,
      selectedTemplate: null,
      Oldcategorie: null,
      TemplateToUpdatecategorie: null,
      idTemplateToUpdate: null,
      Errors: null,
      categorieToAdd: null,
      selectedcategorieToUpdate: null,
      selectedcategorieToDelete: null
    };
  },

  methods: {
    ...mapActions([
      'showTemplate',
      'fetchTemplatesFields',
      'newTemplate',
      'resetErrorlTemplate',
      'setLisImage',
      'getAllImageTemplate',
      'resetErrorTemplate',
      'updateTemplate',
      'deleteTemplate',
      'fetchAllTemplates',
      'fetchTemplateLibre',
      'newCategorieTemplate',
      'updateNameCategorie',
      'deleteCategorie',
      'addRib',
      'blockunblockRib',
      'updateRib',
      'getAllSciTypeSci',
      // 'getSettingFilialeTh',
      'updateTableProductsTemplate',
      'getOneTemplateAfterUpdate',
      'fetchFamilleListForTemplate',
      'fetchAllGroupeTemplate'
    ]),
    initialisetinymce() {
      this.templateToAdd.category_id = this.selectedTemplate.id;
      this.showTemlplate = true;
    },
    hideModal(ref) {
      this.$refs[ref].hide();
      this.resetModal();
      this.TemplateToUpdate = null;
      this.TemplateToUpdateOrigine = null;
    },
    resetModal() {
      this.templateToAdd = {
        name: null,
        category_id: null,
        content: null,
        description: null,
        description2: null,
        group_id: null,
        familles: []
      };
      this.categorieToAdd = null;
      this.TemplateToDelete = null;
      this.TemplateToShow = null;
      this.resetErrorTemplate();
      this.TemplateToUpdatecategorie = null;
      this.TemplateToUpdate = null;
    },
    async handleSubmitTemplate() {
      const body =
        '<!DOCTYPE html><html><head><meta charset="UTF-8"> <title>' +
        this.templateToAdd.name +
        '</title></head><body>' +
        this.templateToAdd.content +
        '</body></html>';
      const response = await this.newTemplate({
        template: {
          content: body,
          name: this.templateToAdd.name,
          category_id: this.templateToAdd.category_id,
          description: this.templateToAdd.description,
          description2: this.templateToAdd.description2,
          group_id: this.templateToAdd.group_id,
          familles: this.templateToAdd.familles.map(i => i.value)
        }
      });
      if (response) {
        this.showTemlplate = false;
      }
    },
    CopyFields(payload) {
      var copyTextarea = document.createElement('textarea');
      copyTextarea.style.position = 'fixed';
      copyTextarea.style.opacity = '0';
      copyTextarea.textContent = payload.prop;
      copyTextarea.value = payload.prop;

      document.body.appendChild(copyTextarea);
      copyTextarea.select();
      document.execCommand('copy');
      document.body.removeChild(copyTextarea);
      this.copierText = true;
      setTimeout(() => {
        this.copierText = false;
      }, 800);
    },
    resetTableTemplateModal() {
      this.columns = [];
      this.sumError = null;
      this.restSum = null;
    },
    CalculeRestWidth() {
      let sum = 0;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].check === true) {
          sum += parseFloat(this.columns[i].width);
        }
      }
      this.restSum = sum;
    },
    async submitDynamicTableTemplate() {
      let bodyFormData = new FormData();
      if (this.border == true) {
        this.border = 1;
      } else {
        this.border = 0;
      }
      let sum = 0;
      for (let i = 0; i < this.columns.length; i++) {
        if (this.columns[i].check === true) {
          sum += parseFloat(this.columns[i].width);
        }
      }
      if (sum !== 100) {
        this.sumError = 'La somme des largeurs être égale a 100';
      } else {
        bodyFormData.append('template_id', this.idTemplateToSend);
        bodyFormData.append('color_bg', this.color);
        bodyFormData.append('border', this.border);
        bodyFormData.append('color', this.label);
        for (let i = 0; i < this.columns.length; i++) {
          bodyFormData.append(
            'columns[' + i + '][column]',
            this.columns[i].column
          );
          bodyFormData.append(
            'columns[' + i + '][column_value]',
            this.columns[i].column_value
          );
          bodyFormData.append('columns[' + i + '][id]', this.columns[i].id);
          bodyFormData.append(
            'columns[' + i + '][index]',
            this.columns[i].index
          );
          bodyFormData.append(
            'columns[' + i + '][width]',
            this.columns[i].width
          );
          bodyFormData.append(
            'columns[' + i + '][blocked]',
            this.columns[i].check === true ? 0 : 1
          );
        }
        const response = await this.updateTableProductsTemplate(bodyFormData);
        if (response) {
          this.showTableTemplateModal = false;
          await this.fetchAllTemplates();
          this.getAllTemplate.map(item1 =>
            item1.templates.map(item2 => {
              if (item2.id === this.idTemplateToSend) {
                this.selectedTemplate.templates.forEach(element => {
                  if (element.id === this.idTemplateToSend) {
                    element.columns = item2.columns;
                    element.color = item2.columns[0].color_bg;
                    element.label = item2.columns[0].color;
                  }
                });
              }
            })
          );
        }
      }
    },
    async handleUpdateClick(template) {
      this.showTemplateToUpdate = true;
      this.TemplateToUpdate = { ...template };
      this.TemplateToUpdateOrigine = template;
      // this.TemplateToUpdatecategorie = template.category_id;
      // const response = await this.getOneTemplateAfterUpdate({
      //   id: template.id
      // });
    },
    async handleUpdateTemplate() {
      const response = await this.updateTemplate({
        TemplateToUpdate: this.TemplateToUpdate,
        TemplateToUpdateOrigine: this.TemplateToUpdateOrigine
      });
      if (response.succes) {
        this.showTemplateToUpdate = false;
        this.TemplateToUpdate = null;
        this.TemplateToUpdateOrigine = null;
      }
    },
    handleDeleteClick(template) {
      this.color;
      this.TemplateToDelete = template;
      this.$refs['deleteTemplateModal'].show();
    },
    handleTableTemplateClick(template) {
      this.showTableTemplateModal = true;
      let newArrayToDisplay = [];
      newArrayToDisplay = template?.columns?.map(item => {
        return { ...item, check: false };
      });
      this.templateToDisplay = newArrayToDisplay;
      this.templateToDisplay?.map(item => {
        this.columns.push({
          id: item.id,
          column: item.column,
          column_value: item.column_value,
          width: item.width,
          index: item.index,
          check: item.blocked === 0 ? true : false
        }),
          (this.color = item.color_bg);
        if (item.border == 1) {
          this.border = true;
        } else {
          this.border = false;
        }
        this.label = item.color;
      });
      this.idTemplateToSend = template?.id;
      for (let i = 0; i < this?.columns?.length; i++) {
        if (this.columns[i].check === true) {
          this.restSum += parseFloat(template.columns[i].width);
        }
      }
    },
    async supprimerTemplate() {
      const response = await this.deleteTemplate({
        template: this.TemplateToDelete
      });
      if (response) {
        this.hideModal('deleteTemplateModal');
      }
    },
    async handleShowClick(Template) {
      this.loading = true;
      this.$refs['showTemplateModal'].show();
      const response = await this.showTemplate(Template.id);
      if (response.success == true) {
        this.loading = false;
        this.pdfsource = response.response;
      } else {
        (this.loading = false), (this.Errors = response.error);
      }
    },
    handleClickTemplate(item) {
      this.selectedTemplate = item;
    },

    addLinetRib() {
      this.TemplateToUpdate.ribs.push({
        swift: null,
        iban: null,
        blocked: false,
        FoundRib: false,
        changeSave: false,
        newLine: true
      });
    },
    DeleteRib(index) {
      this.TemplateToUpdate.ribs.splice(index, 1);
    },
    async handleSubmitRib(index) {
      const response = await this.addRib({
        template_id: this.TemplateToUpdate.id,
        swift: this.TemplateToUpdate.ribs[index].swift,
        iban: this.TemplateToUpdate.ribs[index].iban,
        blocked: this.TemplateToUpdate.ribs[index].blocked,
        item: this.TemplateToUpdate,
        index: index
      });

      if (response == true) {
        this.TemplateToUpdate.ribs[index].FoundRib = true;
        this.TemplateToUpdate.ribs[index].changeSave = false;
        this.TemplateToUpdate.ribs[index].newLine = false;
      }
    },
    async handleUpdateRib(index) {
      const response = await this.updateRib({
        id: this.TemplateToUpdate.ribs[index].id,
        swift: this.TemplateToUpdate.ribs[index].swift,
        iban: this.TemplateToUpdate.ribs[index].iban,
        item: this.TemplateToUpdate,
        index: index
      });
      if (response == true) {
        this.TemplateToUpdate.ribs[index].FoundRib = true;
        this.TemplateToUpdate.ribs[index].changeSave = false;
      }
    },
    async BlockUnblockRibClick(index) {
      const response = await this.blockunblockRib({
        id: this.TemplateToUpdate.ribs[index].id,
        blocked: this.TemplateToUpdate.ribs[index].blocked ? false : true,
        item: this.TemplateToUpdate,
        index: index
      });

      if (response == true) {
      }
    },
    async ChangeIcon(index) {
      this.TemplateToUpdate.ribs[index].changeSave = true;
    },
    async hendleSubmitcategorie() {
      const response = await this.newCategorieTemplate({
        name: this.categorieToAdd
      });
      if (response) {
        this.hideModal('addcategorie');
      }
    },
    handleUpdatecategorie(categorie) {
      this.selectedcategorieToUpdate = { ...categorie };
      this.Oldcategorie = categorie;
      this.$refs['UpdateNamecategorieModal'].show();
    },
    handleDeletecategorie(categorie) {
      this.selectedcategorieToDelete = categorie;

      this.$refs['categorieDeleteModal'].show();
    },
    async submitUpdateNamecategorie() {
      const response = await this.updateNameCategorie({
        categorie: this.selectedcategorieToUpdate,
        oldCategorie: this.Oldcategorie
      });
      if (response) {
        this.hideModal('UpdateNamecategorieModal');
      }
    },
    async handleModalSupprimerValider() {
      const response = await this.deleteCategorie(
        this.selectedcategorieToDelete
      );
      if (response) {
        if (this.selectedcategorieToDelete.templates.length) {
          // this.fetchTemplateLibre({ refresh: true });
          this.selectedTemplate = null;
        }
        if (
          this.selectedcategorie &&
          this.selectedcategorie.name &&
          this.selectedcategorie.name == this.selectedcategorieToDelete.name
        ) {
          this.selectedcategorie = null;
        }

        this.hideModal('categorieDeleteModal');
      }
    }
  },
  components: {
    Card: () => import('../../component/card.vue'),
    'v-input-colorpicker': InputColorPicker,
    Editor,
    Modal: VueModal,
    draggable
  },
  computed: {
    ...mapGetters([
      'checkPermission',
      'getNewTemplateLoading',
      'getImageTemplateList',
      'getTemplatesFields',
      'getTemplateError',
      'getAllTemplate',
      'getTemplateLoading',
      'getLoadingRibTemplate',
      'getErrorRibTemplate',
      'getTemplateRibFound',
      'getSciTypeSci',
      'getlodingDynamicTable',
      'getDynamicTableErrors',
      'getOneTemplate',
      'getLoadingOneTemplate',
      'TemplateLoadingFetch',
      'getTemplateFamilleLoader',
      'getTemplateFamilleData',
      'getGroupeTemplate'
    ]),
    computedFamille() {
      return this.getTemplateFamilleData;
    },
    computedAffectCulomns() {
      let newArrayToDisplay = [];
      newArrayToDisplay = this.templateToDisplay.map(item =>
        this.columns.push({
          id: item.id,
          column: item.column,
          column_value: item.column_value,
          width: item.width,
          index: item.index
        })
      );
      return newArrayToDisplay;
    },
    computedImageList() {
      return this.getImageTemplateList.map(item => {
        return { title: item.title, value: item.url };
      });
    },
    computedDataTinymce() {
      return {
        init: {
          selector: '#editor',
          init_instance_callback: function(editor) {
            var freeTiny = document.querySelector(
              '.tox-notifications-container'
            );
            if (freeTiny) {
              freeTiny.style.display = 'none';
            }
          },
          height: 450,
          fullpage_default_doctype: '<!DOCTYPE html>',
          fullpage_default_encoding: 'UTF-8',
          plugins: [
            'advlist autolink lists link image  print preview hr',
            'searchreplace visualblocks code ',
            'insertdatetime  table paste imagetools charmap'
          ],
          fontsize_formats: '8pt 10pt 12pt 14pt 16pt 18pt 24pt 36pt 48pt 50pt',

          toolbar_mode: 'floating',
          powerpaste_allow_local_images: true,
          language: 'fr_FR',
          toolbar:
            ' undo redo | bold italic underline | alignleft aligncenter alignright alignjustify |  link image |' +
            ' bullist  numlist outdent indent  |  forecolor backcolor  |' +
            '   print preview  fullpage |' +
            ' hr charmap',
          menubar: 'favs file edit view insert format   ',
          menu: {
            format: {
              title: 'Format',
              items:
                'bold italic underline strikethrough superscript subscript codeformat | formats blockformats fontformats fontsizes align | forecolor backcolor | removeformat '
            }
          },
          contextmenu: false,
          removed_menuitems: 'paste pastetext',
          block_formats: 'box=alert-box; badge=badge; footer=footer',
          formats: {
            'alert-box': {
              block: 'div',
              styles: {
                'background-color': '#f4f5f9',
                padding: '20px'
              }
            },
            footer: {
              block: 'div',
              styles: {
                'background-color': ' #c7c7c7',
                padding: '20px'
              }
            },
            badge: {
              title: 'Badge',
              inline: 'span',
              styles: {
                display: 'inline-block',
                border: '.1rem solid transparent',
                'border-radius': '50px',
                padding: '1rem',
                'margin-top': '0px',
                'margin-bottom': '1rem',
                color: '#2276d2',
                'box-shadow': '2px 3px #d6d7da',
                'background-color': '#008cd6',
                color: 'white'
              }
            }
          },
          /* enable title field in the Image dialog*/
          image_title: true,
          /* enable automatic uploads of images represented by blob or data URIs*/
          automatic_uploads: true,
          image_list: success => success(this.computedImageList),
          file_picker_types: 'image',
          images_upload_handler: async (blobInfo, success, failure) => {
            let param = new FormData();
            param.append('image', blobInfo.blob());
            this.setLisImage(param)
              .then(response => {
                success(response.data.data.url);
              })
              .catch(error => {
                failure('HTTP Error: ' + error.message);
              });
          }
        }
      };
    },

    computedCategorie() {
      let tab = this.getAllTemplate.map(item => {
        return { value: item.id, text: item.name };
      });
      return tab;
    }
  },
  async mounted() {
    this.fetchTemplatesFields();
    this.getAllImageTemplate();
    this.fetchFamilleListForTemplate();
    this.fetchAllGroupeTemplate();
    this.fetchAllTemplates();
    // this.fetchTemplateLibre();
    // await this.getSettingFilialeTh();
  }
};
</script>

<style lang="scss" scoped>
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
.border-checkbox {
  margin: auto;
  display: block;
}
.active {
  color: red !important;
  cursor: pointer;
}
.sort-icon {
  height: 20px;
  width: 20px;
}

.color-message {
  color: red;
  font-size: 10px;
}
.gestion-template-th {
  .list-group {
    .list-group-item {
      .content-template {
        display: flex;
        justify-content: space-between;
        align-items: center;
        .title {
          width: 300px;
          font-size: 14px;
          font-weight: 800;
          font-family: 'Montserrat', sans-serif;
          color: #2a2a2a;
        }
        .btn-action {
          display: block;
          margin-right: 20px;
          white-space: nowrap;
          button {
            margin: 0 2px;
          }
        }
      }
    }
  }
  .template-block-style {
    height: calc(100vh - 165px);
    display: block;
    overflow-y: auto;
    width: 78%;
  }
  .templates-block {
    display: flex;
    justify-content: space-between;
    .list-groupe-item-groupe-template {
      width: 200px;
    }
  }
}
.color_picker_width {
  width: 35px;
  height: 25px;
  border: solid 1px;
  margin: auto;
  display: block;
}
.rib_block {
  color: #000;
  position: relative;
  overflow: visible;
  font-size: 12px;
  margin-top: 5px;
  border: 1px solid rgba(108, 117, 125, 0.3215686275);
  padding: 4px;
  background-color: #f5f4fa;
  width: 99%;
  border-radius: 4px;
  margin: 0px auto;
  .button-add-rib {
    display: flex;
  }
  .edit-action {
    width: 5%;
    display: flex;
    font-size: 7px;
    justify-content: space-between;
    height: 80px;
    align-content: center;
    align-items: center;
    margin-right: 2%;
    .action-icon {
      cursor: pointer;
    }
    .validate {
      color: green;
    }
    .cancel {
      color: #ab2525;
    }
  }
  .line-rib {
    display: flex;
    justify-content: space-between;
  }
  .rib-add-btn {
    font-size: 9px;
    min-width: 70px;
    text-align: center;
    background-color: #7a78dc;
    border-radius: 3px;
    border: none;
    font-family: 'Montserrat', sans-serif;
    font-weight: 500;
    padding: 5px;
    color: #ffffff;
  }
}
.loadingFetch {
  width: 16px;
  height: 16px;
  font-size: 10px;
}
</style>
<style lang="scss">
.titleFields {
  display: flex;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;
  .CopieMsg {
    margin-left: 50px;
    color: #4d4bac;
    font-weight: 12px;
  }
}
.filed-class {
  border: 1px solid #6c757d4d;
  background-color: #ebebeb5c;
  border-radius: 4px;
  position: relative;
  padding: 5px;
  width: 211px;
  margin: 4px;
  cursor: pointer;
  font-size: 12px;
  font-weight: 600;
  font-family: 'Montserrat', sans-serif;
  color: #2a2a2a;

  .icon-field {
    position: absolute;
    left: 6px;
    width: 25px;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .icon-copy {
    position: absolute;
    right: 6px;
    width: 25px;
    top: 30%;
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
  }
  .value-field {
    display: -webkit-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: justify;
    -ms-flex-pack: justify;
    justify-content: space-between;
    margin-left: 25px;
  }
}
.width-custom-modal {
  .modal-dialog {
    max-width: 530px;
  }
}
.tox-notifications-container {
  display: none !important;
}
.ModelClass {
  border-radius: 5px;
  max-width: 800px;
  width: auto;
  .vm-title {
    font-size: 1.25rem;
  }
}
.template-block-style::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.template-block-style::-webkit-scrollbar-track,
template-style {
  box-shadow: inset 0 0 5px #eae7f6;
  border-radius: 10px;
}
.template-block-style::-webkit-scrollbar-thumb {
  background: #8d8cb7;
  border-radius: 7px;
}
.style-half-circle-loder {
  padding-top: 0px !important;
  background-color: #fff !important;
}
</style>
